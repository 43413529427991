import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export const slideInOut: AnimationTriggerMetadata = trigger('slideInOut', [
  transition(':enter', [
    style({ transform: 'translateY(0px) translateX(1000px)', opacity: 0 }),
    animate(
      '500ms cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      style({ transform: 'translateY(0px) translateX(0)', opacity: 1 })
    ),
  ]),
  transition(':leave', [
    animate(
      '0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      style({ transform: 'translateY(0px) translateX(100%)', opacity: 0 })
    ),
  ]),
]);
